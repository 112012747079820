(function ($) {
    
    var cmsuser = {

        canRead : function() {
            var accessMethods = parseInt($.cdh.accessMethods);
            return accessMethods == (accessMethods | $.cdh.enums.accessMethod.read);
        },

        canInsert : function () {
            var accessMethods = parseInt($.cdh.accessMethods);
            return accessMethods == (accessMethods | $.cdh.enums.accessMethod.insert);
        },

        canUpdate : function() {
            var accessMethods = parseInt($.cdh.accessMethods);
            return accessMethods == (accessMethods | $.cdh.enums.accessMethod.update);
        },

        canDelete : function () {
            var accessMethods = parseInt($.cdh.accessMethods);
            return accessMethods == (accessMethods | $.cdh.enums.accessMethod.delete);
        }
    };
    
    var employee = {
        allowedToUpdateDirectDepositInformation: function () {
           return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateDirectDepositInformation);
        },
        allowedToViewDirectDepositInformation: function () {
           return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAccessDirectDepositInformation);
        },
        allowedToEditEmail: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateEmailAddress);
        },
        allowedToUpdateParticipantCommunication: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateParticipantCommunication);
        },
        allowAutoPaymentPreference: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateClaimCrossoverAutoPay);
        },
        allowAutoReimbursementPreference: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateAutoReimbursementSettings);
        },
        allowEnterClaimRequests: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionEnterClaimRequests);
        },
        hasAccessToProfileDemographicInfo: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);
        },
        hasAccessToDependentDemographicInfo: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateDependentDemographicInformation);
        },
        employerNoDebitCard: function() {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionEmployerNoDebitCard);
        },
        allowParticipantTransaction: () => $.cdh.helper.hasPermission($.cdh.enums.userPermission.allowParticipantTransaction),
        hasAccessToMarkAsInelgible: function () {
            return $.cdh.helper.hasPermission($.cdh.enums.userPermission.allowParticipantMarkAsInelgibile);
        }
    };
    
    $.extend(true, $, {
        cdh: {
            cmsuser: cmsuser,
            employee: employee
        }
    });

    if ($.cdh.user.isEmployee) {
        var setEmployeeData = function (data) {
            var customerCare = data.CustomerCare;
            $.cdh.employee.customerCare.url(customerCare.Url);
            $.cdh.employee.customerCare.phone(customerCare.Phone);
            $.cdh.employee.customerCare.notes(customerCare.Notes);

            $.cdh.employee.submitClaimAllowed(data.SubmitClaimAllowed);
            $.cdh.employee.canRequestReimbursement(data.CanRequestReimbursement);

            setTimeout(updateFooterHeight, 2000);
        };

        var cacheKey = "employeeData_" + $.cdh.user.languagePreference + "_" + $.cdh.user.uniqueHash;
        var cachedData = $.cdh.helper.getObjectFromLocalStorage(cacheKey);
        if (cachedData) {
            setEmployeeData(cachedData);
        } else {
            var employeeDataRequest = new $.cdh.get.profile.employeeData(function (data) {
                if (data) {
                    setEmployeeData(data);
                }
                $.cdh.helper.setObjectToLocalStorage(cacheKey, data || {}, 5);
            });

            employeeDataRequest.load();
        }

        let lastLoginUtcCookieKey = "LastLoginUtc";
        let lastLoginUtcStorageKey = lastLoginUtcCookieKey + "_" + $.cdh.user.uniqueHash;
        let lastLoginUtc = $.cdh.helper.getCookie(lastLoginUtcCookieKey);
        if (lastLoginUtc) {
            $.cdh.localStorage.setObject(lastLoginUtcStorageKey, lastLoginUtc);
            $.cdh.helper.deleteCookie(lastLoginUtcCookieKey);
        } else {
            lastLoginUtc = $.cdh.localStorage.getObject(lastLoginUtcStorageKey);
        }

        $.cdh.user.lastLoginLocalDate(lastLoginUtc ? moment(lastLoginUtc).local() : null);
    }

})(jQuery);
(function ($) {

    const titleName = "Title";

    function replaceTemplate(template, values, limitSymbols) {
        if (!limitSymbols) {
            limitSymbols = ['<', '>'];
        }

        for (let key in values) {
            template = template.replace(limitSymbols[0] + key + limitSymbols[1], values[key]);
        }

        return template
    }

    var widgetLabels = {
        goGreenAll: {
            name: 'Go-Green-All',
            default: 'Go Green! Opt for electronic delivery today and avoid additional fees for paper statements and tax forms'
        },

        goGreenStatements: {
            name: 'Go-Green-Statements',
            default: 'Go Green! Opt for electronic delivery today and avoid additional fees for paper statements'
        },

        goGreenTaxForms: {
            name: 'Go-Green-TaxForms',
            default: 'Go Green! Opt for electronic delivery today and avoid additional fees for paper tax forms'
        },

        payItBack: {
            title: {
                name: titleName,
                default: 'Pay It Back'
            },
            balanceDueLessThanThreshold: {
                name: 'Balance Due < Threshold',
                default: 'You currently have an outstanding balance due of: <BalanceDue>. If this balance exceeds <ThresholdAmt> then your card may be suspended.',
                replace: function (template, balanceDue, thresholdAmt, limitSymbols) {
                    var values = {
                        BalanceDue: balanceDue,
                        ThresholdAmt: thresholdAmt
                    };

                    return replaceTemplate(template, values, limitSymbols);
                }
            },
            balanceDueMoreThanThreshold: {
                name: 'Balance Due >= Threshold',
                default: 'You currently have an outstanding balance due of: <BalanceDue> which exceeds the limit of <ThresholdAmt>, the use of your debit card has been suspended.'
            }
        },
        balanceDue: {
            title: {
                name: titleName,
                default: 'Balance Due'
            }
        },
        scheduledPayments: {
            title: {
                name: titleName,
                default: 'Scheduled Payments'
            }
        }
    };

    widgetLabels.payItBack.balanceDueMoreThanThreshold.replace = widgetLabels.payItBack.balanceDueLessThanThreshold.replace;

    $.extend(true, $, {
        cdh: {
            widgetLabels: widgetLabels
        }
    });

})(jQuery);
(function ($) {

    var templateExtractor = function (template) {
        this.getLabel = function (name, value) {
            if (template && template.customLabels) {                
                var result = $.grep(template.customLabels, function (item) { return item.name === name; });
                return result.length > 0 && result[0].value ? decodeURIComponent(result[0].value) : value;
            }
            return value;
        }

        this.getTemplate = function (name, value) {
            if (template && template.customFields) {
                var result = $.grep(template.customFields, function (item) {return item.name === name;});
                return result.length > 0 && result[0].value ? decodeURIComponent(result[0].value) : value;
            }
            return value;
        }
    };

    $.extend(true, $, {
        cdh: {
            services: {
                templateExtractor: templateExtractor
            }
        }
    });
})(jQuery);
(function ($) {

    let localStorageHelper = {
        setObject: function (key, object) {
            localStorage.setItem(key, object ? JSON.stringify(object) : null);
        },
        getObject: function (key) {
           let strValue = localStorage.getItem(key);
           if(strValue) {
               try {
                   return JSON.parse(strValue);
               }
               catch (e){
               }
           }
           return null;
        }
    };

    $.extend(true, $, {
        cdh: {
            localStorage: localStorageHelper
        }
    });
})(jQuery);